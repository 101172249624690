<template>
  <data-form
    :data="data"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
  />
</template>

<script>
import DataForm from "../widgets/DataForm.vue";
import CustomerTypesDataService from "../../services/customer-types.service";

export default {
  name: "type-form",
  props: ["data"],
  components: {
    DataForm,
  },
  data() {
    return { message: "" };
  },
  computed: {
    formFields() {
      return {
        id: { type: "hidden" },
        name: { label: "Naziv", type: "text" },
      };
    },
  },
  methods: {
    saveData(newData) {
      if (newData && newData.id) {
        this.update(newData);
      } else {
        this.create(newData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      CustomerTypesDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Podaci su promenjeni!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      CustomerTypesDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Kupac je kreiran!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>